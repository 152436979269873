<template>
  <div>
    <el-form ref="form" :model="form">
      <el-row :gutter="20">
        <el-col :span="12">
          <div class="app-form__group mb-4">
             <span class="input--label d-block mb-2"> {{$t('message.skill')}} </span>
            <el-input
              :placeholder="$t('message.skill')"
              v-model="form.skill"
              size="medium"
            ></el-input>
          </div>
        </el-col>
        <!-- end col -->
        <el-col :span="12">
          <div class="app-form__group mb-4">
             <span class="input--label d-block mb-2">{{$t('message.degree')}} </span>
             <el-select
              class="w-100"
              v-model="form.level"
              placeholder="Степень"
              size="medium"
            >
              <el-option label="Немного" value="Немного"></el-option>
              <el-option label="Средний" value="Средний"></el-option>
              <el-option label="Хороший" value="Хороший"></el-option>
              <el-option label="Очень хороший" value="Очень хороший"></el-option>
            </el-select>
          </div>
        </el-col>
        <!-- end col -->
      </el-row>
    </el-form>
    <span slot="footer" class="dialog-footer my-style-modal">
      <el-button
        type="success"
        size="medium"
        @click="submit()"
        plain
      >
       {{$t("message.save")}}
      </el-button>
      <el-button
        type="warning"
        size="medium"
        @click="close()"
        plain
      >
        {{$t("message.close")}}
      </el-button>
    </span>
  </div>
</template>
<script>
import { i18n } from "@/utils/i18n";
import { mapGetters, mapActions, mapState } from "vuex";
export default {
  data() {
    return {
      form: {},
      load: false
    };
  },
  props:{
      skill:{
          type: Object,
          required: true
      }
  },
  watch:{
      skill: {
          handler: function()
          {
              this.opened();
          },
          immediate:true,
          deep:true
      }
  },
  computed: {},
  methods: {
    ...mapActions({
      updateStaffSkill: "profile/updateStaffSkill",
      mode: "MODE"

    }),
    submit() {
      this.form.staff_id = this.$route.params.id;
      this.form.skill_id=this.skill.id;
      if (this.form.staff_id) {
        this.updateStaffSkill(this.form)
          .then((res) => {
            this.$alert(res);
            if (res.status == 201) {             
              this.parent().fetchStaffSkill();
              this.close();
            }
          })
          .catch((err) => {
            this.$alert(err);
          });
      }
    },
    close() {
      this.clearForm()
      this.parent().closeSkillDialog();
    },
    parent() {
      return this.$parent.$parent;
    },    
    opened() {
      this.load=true
      this.form = JSON.parse(JSON.stringify(this.skill));
      this.load=false
    },
    clearForm()
    {
        for (var key in this.form) {
        this.form[key] = null;
      }
    }
  },
};
</script>
