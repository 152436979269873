<template>
  <div>
    <el-form ref="form" :model="form" v-loading="load">
      <el-row :gutter="20">
        <el-col :span="12">
          <div class="app-form__group mb-4">
             <span class="input--label d-block mb-2">{{$t('message.educational_institution')}}</span>
            <crm-input
              :inputValue="form.name"
              :placeholder="$t('message.educational_institution')"
              v-model="form.name"
              :size="'medium'"
            ></crm-input>
          </div>
        </el-col>
        <!-- end col -->
        <el-col :span="12">
          <div class="app-form__group mb-4">
             <span class="input--label d-block mb-2"> {{$t('message.degree')}} </span>
            <!-- <crm-input
             :inputValue="form.level"
              placeholder="Степень "
              v-model="form.level"
              size="medium"
            ></crm-input> -->
              <select-education
              v-model="form.level"
            ></select-education>
          </div>
        </el-col>

        <el-col :span="12">
          <div class="app-form__group mb-4">
              <span class="input--label d-block mb-2"> {{$t('message.faculty')}} </span>
            <crm-input
             :inputValue="form.faculty"
             :placeholder="$t('message.faculty')"
              v-model="form.faculty"
              :size="'medium'"
            ></crm-input>
          </div>
        </el-col>
        <!-- end col -->

        <el-col :span="12">
          <div class="app-form__group mb-4">
            <span class="input--label d-block mb-2"> {{$t('message.beginning_date')}} </span>
            <crm-date-picker
             :placeholder="$t('message.beginning_date')"
              v-model="form.started_date"
              :date="form.started_date"
              size="medium"
            ></crm-date-picker>
          </div>
        </el-col>
        <el-col :span="12">
          <div class="app-form__group mb-4">
            <span class="input--label d-block mb-2"> {{$t('message.expiration_date')}} </span>
            <crm-date-picker
              :placeholder="$t('message.expiration_date')"
              v-model="form.finished_date"
              :date="form.finished_date"
              size="medium"
            ></crm-date-picker>
          </div>
        </el-col>
        <!-- end col -->
      </el-row>
    </el-form>
    <span slot="footer" class="dialog-footer my-style-modal">
      <el-button type="success" size="medium" @click="submit()" plain>
        {{$t("message.save")}}
      </el-button>
      <el-button type="warning" size="medium" @click="close()" plain>
         {{$t("message.close")}}
      </el-button>
    </span>
  </div>
</template>
<script>
import { i18n } from "@/utils/i18n";
import { mapGetters, mapActions, mapState } from "vuex";
export default {
  data() {
    return {
      form: {},
      load: false
    };
  },
  props:{
      education:{
          type: Object,
          required: true
      }
  },
  watch:{
      education: {
          handler: function()
          {
              this.opened();
          },
          immediate:true,
          deep:true
      }
  },
  computed: {},
  methods: {
    ...mapActions({
      updateStaffEducation: "profile/updateStaffEducation",
      mode: "MODE"
    }),
    submit() {
      this.form.staff_id = this.$route.params.id;
      this.form.education_id=this.education.id;
      if (this.form.staff_id) {
        this.updateStaffEducation(this.form)
          .then((res) => {
            this.$alert(res);
            if (res.status == 201) {
              this.clearForm()
              this.parent().fetchStaffEducation();
              this.close();
            }
          })
          .catch((err) => {
            this.$alert(err);
          });
      }
    },
    close() {
      this.clearForm()
      this.parent().closeEducationDialog();
    },
    parent() {
      return this.$parent.$parent;
    },
    closed() {
      if (_.isFunction(this.afterLeave)) {
        this.afterLeave();
      }
    },
    opened() {
      this.load=true
      this.form = JSON.parse(JSON.stringify(this.education));
      this.load=false
    },
    clearForm()
    {
        for (var key in this.form) {
        this.form[key] = null;
      }
    }
  },
};
</script>
