<template>
  <div class="five-st" >
    <div class="card-table mb-5" :class="mode ? 'cardtable__day' : 'cardtable__night'">
      <div class="workers-info-title-za">
        <div class="title"> {{$t("message.labor_contract")}}</div>
        <!-- <i class="el-icon-edit"></i> -->
      </div>
      <el-button
        class="seva-document"
        type="success"
        size="small"
        @click="submit('One')"
      >
         {{$t("message.save")}}
      </el-button>
      <div
        class="workers-info madal-info-workers p-5 pt-2 pb-2"
        v-loading="fileLoaderOne"
      >
        <el-upload
          class="upload-demo mu-styl-doc"
          action="/"
          :on-preview="handlePreview"
          :on-remove="handleRemove"
          :on-change="createDocumentListOne"
          :file-list="documentListOne"
          :auto-upload="false"
        >
          <el-button size="small" type="primary"> {{$t("message.add_document")}}</el-button>

          <div slot="tip" class="el-upload__tip">
            {{ $t("message.supported_formats") }}
            <br />{{ $t("message.size_limit_5") }}
          </div>
        </el-upload>
      </div>
    </div>
    <div class="card-table mb-5" :class="mode ? 'cardtable__day' : 'cardtable__night'">
      <div class="workers-info-title-za">
        <div class="title"> {{$t("message.material_liability")}}</div>
        <!-- <i class="el-icon-edit"></i> -->
      </div>
      <el-button
        class="seva-document"
        type="success"
        size="small"
        @click="submit('Two')"
      >
         {{$t("message.save")}}
      </el-button>
      <div
        class="workers-info madal-info-workers p-5 pt-2 pb-2"
        v-loading="fileLoaderTwo"
      >
        <el-upload
          class="upload-demo mu-styl-doc"
          action="/"
          :on-preview="handlePreview"
          :on-remove="handleRemove"
          :on-change="createDocumentListTwo"
          :file-list="documentListTwo"
          :auto-upload="false"
        >
          <el-button size="small" type="primary"> {{$t("message.add_document")}} </el-button>

          <div slot="tip" class="el-upload__tip">
            {{ $t("message.supported_formats") }}
            <br />{{ $t("message.size_limit_5") }}
          </div>
        </el-upload>
      </div>
    </div>
    <div class="card-table mb-5" :class="mode ? 'cardtable__day' : 'cardtable__night'">
      <div class="workers-info-title-za">
        <div class="title">{{$t("message.confidentiality_agreement")}}</div>
        <!-- <i class="el-icon-edit"></i> -->
      </div>
      <el-button
        class="seva-document"
        type="success"
        size="small"
        @click="submit('Three')"
      >
         {{$t("message.save")}}
      </el-button>
      <div
        class="workers-info madal-info-workers p-5 pt-2 pb-2"
        v-loading="fileLoaderThree"
      >
        <el-upload
          class="upload-demo mu-styl-doc"
          action="/"
          :on-preview="handlePreview"
          :on-remove="handleRemove"
          :on-change="createDocumentListThree"
          :file-list="documentListThree"
          :auto-upload="false"
        >
          <el-button size="small" type="primary">{{$t("message.add_document")}} </el-button>

          <div slot="tip" class="el-upload__tip">
            {{ $t("message.supported_formats") }}
            <br />{{ $t("message.size_limit_5") }}
          </div>
        </el-upload>
      </div>
    </div>
    <div class="card-table mb-5" :class="mode ? 'cardtable__day' : 'cardtable__night'">
      <div class="workers-info-title-za">
        <div class="title">{{$t("message.employment_contract")}}</div>
        <!-- <i class="el-icon-edit"></i> -->
      </div>
      <el-button
        class="seva-document"
        type="success"
        size="small"
        @click="submit('Four')"
      >
         {{$t("message.save")}}
      </el-button>
      <div
        class="workers-info madal-info-workers p-5 pt-2 pb-2"
        v-loading="fileLoaderFour"
      >
        <el-upload
          class="upload-demo mu-styl-doc"
          action="/"
          :on-preview="handlePreview"
          :on-remove="handleRemove"
          :on-change="createDocumentListFour"
          :file-list="documentListFour"
          :auto-upload="false"
        >
          <el-button size="small" type="primary">{{$t("message.add_document")}} </el-button>

          <div slot="tip" class="el-upload__tip">
            {{ $t("message.supported_formats") }}
            <br />{{ $t("message.size_limit_5") }}
          </div>
        </el-upload>
      </div>
    </div>
    <div class="card-table mb-5" :class="mode ? 'cardtable__day' : 'cardtable__night'">
      <div class="workers-info-title-za">
        <div class="title">{{$t("message.finance_training")}}</div>
        <!-- <i class="el-icon-edit"></i> -->
      </div>
      <el-button
        class="seva-document"
        type="success"
        size="small"
        @click="submit('Five')"
      >
         {{$t("message.save")}}
      </el-button>
      <div
        class="workers-info madal-info-workers p-5 pt-2 pb-2"
        v-loading="fileLoaderFive"
      >
        <el-upload
          class="upload-demo mu-styl-doc"
          action="/"
          :on-preview="handlePreview"
          :on-remove="handleRemove"
          :on-change="createDocumentListFive"
          :file-list="documentListFive"
          :auto-upload="false"
        >
          <el-button size="small" type="primary">{{$t("message.add_document")}} </el-button>

          <div slot="tip" class="el-upload__tip">
            {{ $t("message.supported_formats") }}
            <br />{{ $t("message.size_limit_5") }}
          </div>
        </el-upload>
      </div>
    </div>
  </div>
</template>
<script>
import { i18n } from "@/utils/i18n";

import { mapGetters, mapActions } from "vuex";
export default {
  data() {
    return {
      documentListOne: [],
      documentListTwo: [],
      documentListThree: [],
      documentListFour: [],
      documentListFive: [],
      form: {},
      baseUrl: process.env.VUE_APP_URL.substring(
        0,
        process.env.VUE_APP_URL.length - 3
      ),
      fileLoaderOne: false,
      fileLoaderTwo: false,
      fileLoaderThree: false,
      fileLoaderFour: false,
      fileLoaderFive: false,
    };
  },
  created() {
    this.getDocuments();
  },
  computed: {
    ...mapGetters({
      documents: "profile/staff_documents_five",
      mode: "MODE"
    }),
  },
  methods: {
    ...mapActions({
      save: "profile/uploadStaffDocumentFive",
      getDocument: "profile/getStaffDocumentFive",
      deleteStaffDocument: "profile/deleteStaffDocument",
    }),
    handleRemove(file, doc) {
      if (
        file.hasOwnProperty("id") &&
        this.$route.params.id &&
        !this.fileLoader
      ) {
        this.fileLoader = true;
        this.deleteStaffDocument({
          staff_id: this.$route.params.id,
          file_id: file.id,
        })
          .then((res) => {
            this.fileLoader = false;
            if (res.status == 200) {
              this.getDocuments();
            }
            this.$alert(res);
          })
          .catch((error) => {
            this.$alert(error);
            this.fileLoader = false;
          });
      } else {
        this.getDocuments();
      }
    },
    handlePreview(file) {
      window.open(file.url, "_blank");
    },
    submit(submitType) {
      if (
        !this["fileLoader" + `${submitType}`] &&
        this.$route.params.id &&
        _.size(this["documentList" + `${submitType}`]) > 0
      ) {
        this["fileLoader" + `${submitType}`] = true;
        let formData = new FormData();
        for (const key in this["documentList" + `${submitType}`]) {
          if (
            this["documentList" + `${submitType}`][key].hasOwnProperty("url")
          ) {
            delete this["documentList" + `${submitType}`][key];
          }
          if (this["documentList" + `${submitType}`].hasOwnProperty(key)) {
            const element = this["documentList" + `${submitType}`][key];
            formData.append(`documents${submitType}[${key}]`, element);
          }
        }
        formData.append(`documents_type`, "documents" + submitType);
        formData["staff_id"] = this.$route.params.id;
        this.save(formData)
          .then((res) => {
            this.$alert(res);
            this["fileLoader" + `${submitType}`] = false;
            if (res.status == 201) {
              this.getDocuments();
            }
          })
          .catch((err) => {
            this.$alert(err);
            this["fileLoader" + `${submitType}`] = false;
            this.getDocuments();
          });
      }
    },
    getDocuments() {
      if (this.$route.params.id) {
        this.getDocument(this.$route.params.id)
          .then((res) => {
            if (res.status == 200) {
              this.documentListOne = [];
              let documentListOne = _.filter(this.documents, [
                "file_type",
                "documentsOne",
              ]);
              for (let document in documentListOne) {
                this.documentListOne.push({
                  uid: documentListOne[document].id,
                  id: documentListOne[document].id,
                  name: documentListOne[document].file_name,
                  url: this.baseUrl + documentListOne[document].path,
                });
              }
              this.documentListTwo = [];
              let documentListTwo = _.filter(this.documents, [
                "file_type",
                "documentsTwo",
              ]);
              for (let document in documentListTwo) {
                this.documentListTwo.push({
                  uid: documentListTwo[document].id,
                  id: documentListTwo[document].id,
                  name: documentListTwo[document].file_name,
                  url: this.baseUrl + documentListTwo[document].path,
                });
              }
              this.documentListThree = [];
              let documentListThree = _.filter(this.documents, [
                "file_type",
                "documentsThree",
              ]);
              for (let document in documentListThree) {
                this.documentListThree.push({
                  uid: documentListThree[document].id,
                  id: documentListThree[document].id,
                  name: documentListThree[document].file_name,
                  url: this.baseUrl + documentListThree[document].path,
                });
              }
              this.documentListFour = [];
              let documentListFour = _.filter(this.documents, [
                "file_type",
                "documentsFour",
              ]);
              for (let document in documentListFour) {
                this.documentListFour.push({
                  uid: documentListFour[document].id,
                  id: documentListFour[document].id,
                  name: documentListFour[document].file_name,
                  url: this.baseUrl + documentListFour[document].path,
                });
              }
              this.documentListFive = [];
              let documentListFive = _.filter(this.documents, [
                "file_type",
                "documentsFive",
              ]);
              for (let document in documentListFive) {
                this.documentListFive.push({
                  uid: documentListFive[document].id,
                  id: documentListFive[document].id,
                  name: documentListFive[document].file_name,
                  url: this.baseUrl + documentListFive[document].path,
                });
              }
            }
          })
          .catch((error) => {
            this.$alert(error);
          });
      }
    },
    createDocumentListOne(file) {
      this.documentListOne.push(file.raw);
    },
    createDocumentListTwo(file) {
      this.documentListTwo.push(file.raw);
    },
    createDocumentListThree(file) {
      this.documentListThree.push(file.raw);
    },
    createDocumentListFour(file) {
      this.documentListFour.push(file.raw);
    },
    createDocumentListFive(file) {
      this.documentListFive.push(file.raw);
    },
  },
};
</script>
<style lang="scss" >
.card-table .seva-document {
  position: relative;
  left: 189px;
  top: 39px;
}
.five-st .el-upload-list__item-name {
  font-size: 16px;
  padding: 6px 4px;
}
.five-st .el-upload-list__item-status-label .el-icon-circle-check,
.five-st .el-upload-list__item .el-icon-close {
  font-size: 24px;
  padding-top: 3px;
}
.five-st .workers-info-title-za {
  margin-bottom: -25px;
}
</style>
